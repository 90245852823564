<template>
  <svg
    width="19"
    height="21"
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5914 5.16368C13.6151 5.16368 16.0399 7.56852 16.0399 10.5674C16.0399 13.5663 13.6151 15.9711 10.5914 15.9711C7.56768 15.9711 5.14001 13.5691 5.14001 10.5674C5.14001 7.56565 7.56479 5.16368 10.5914 5.16368ZM10.4757 18.4907C6.01675 18.4247 2.46929 14.8089 2.53295 10.3866C2.5966 5.96434 6.24534 2.44604 10.7043 2.50918C13.2853 2.54648 15.5625 3.76899 16.9948 5.64866L19 4.12771C17.1047 1.67121 14.1273 0.0498141 10.739 0.00102861C4.89696 -0.0793239 0.0850235 4.55817 0.00111128 10.3522C-0.082801 16.1404 4.59603 20.9185 10.4381 20.9989C13.8293 21.0477 16.8472 19.5095 18.8119 17.1046L16.853 15.5234C15.3657 17.3658 13.0567 18.5223 10.4757 18.485V18.4907Z"
      fill="currentColor"
    />
  </svg>
</template>
